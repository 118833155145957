import React, { useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { red, orange, blue, green } from '@mui/material/colors';

const BandwidthTest = ({ bandwidth, setBandwidth }) => {

    useEffect(() => {
        const testBandwidth = () => {
            const imageAddr =
                "https://upload.wikimedia.org/wikipedia/commons/3/3f/Fronalpstock_big.jpg";
            const downloadSize = 5139441; // bytes (approximately 5.1 MB)

            ShowProgressMessage("Checking connection speed, please wait...");

            MeasureConnectionSpeed(imageAddr, downloadSize)
                .then((speedMbps) => {
                    let connectionSpeedStatus = ["weak", "ok", "excellent"];
                    let speedStatus = connectionSpeedStatus[2];

                    if (speedMbps < 60) {
                        speedStatus = connectionSpeedStatus[0];
                    } else if (speedMbps >= 60 && speedMbps <= 100) {
                        speedStatus = connectionSpeedStatus[1];
                    }

                    ShowProgressMessage([
                        `Your connection speed is ${speedStatus}: ${speedMbps} Mbps`,
                    ]);

                    setBandwidth(parseInt(speedMbps));
                })
                .catch((error) => {
                    ShowProgressMessage("Invalid image, or error downloading");
                    console.error(error);
                });
        };

        testBandwidth();
    }, []);

    const ShowProgressMessage = (msg) => {
        console.log(msg); // You can customize this to display the message somewhere in your UI if needed.
    };

    const MeasureConnectionSpeed = (imageAddr, downloadSize) => {
        return new Promise((resolve, reject) => {
            var startTime, endTime;
            var download = new Image();
            download.onload = function () {
                endTime = new Date().getTime();
                var duration = (endTime - startTime) / 1000;
                var bitsLoaded = downloadSize * 8;
                var speedMbps = (bitsLoaded / duration / 1024 / 1024).toFixed(2);
                resolve(speedMbps);
            };

            download.onerror = function (err) {
                reject(err);
            };

            startTime = new Date().getTime();
            var cacheBuster = "?nnn=" + startTime;
            download.src = imageAddr + cacheBuster;
        });
    };

    let status = {};
    if (bandwidth < 60) {
        status.text = "Bad";
        status.color = red[500];
    } else if (bandwidth >= 60 && bandwidth <= 100) {
        status.text = "OK";
        status.color = orange[500];
    } else if (bandwidth > 100 && bandwidth <= 200) {
        status.text = "Good";
        status.color = blue[500];
    } else if (bandwidth > 200 && bandwidth <= 400) {
        status.text = "Great";
        status.color = blue[200];
    } else {
        status.text = "Excellent";
        status.color = green[500];
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
                p: 1,
                width: "100%",
                backgroundColor: "background.paper",
            }}
        >
            <Typography variant="subtitle2" color="text.secondary">
                Connection Speed
            </Typography>
            {bandwidth ? (
                <Typography variant="h5" sx={{ color: status.color }}>
                    {status.text}
                </Typography>
            ) : (
                <CircularProgress />
            )}
            <Typography variant="h6">
                {bandwidth ? `${bandwidth} Mbps` : "Testing..."}
            </Typography>
        </Box>
    );
};

export default BandwidthTest;
