import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Box, Modal, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function ContestRules() {
    const [open, setOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const goToPrevPage = () => setPageNumber((prevPageNumber) => prevPageNumber - 1 <= 1 ? 1 : prevPageNumber - 1);
    const goToNextPage = () => setPageNumber((prevPageNumber) => prevPageNumber + 1 >= numPages ? numPages : prevPageNumber + 1);

    return (
        <span>
            <Typography
                component="span"
                variant="body1"
                onClick={handleOpen}
                sx={{ cursor: 'pointer', color: 'text.secondary', '&:hover': { textDecoration: 'underline' } }}
            >
                Contest Rules
            </Typography>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '90%', sm: '70%' },
                        height: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto',
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Contest Rules
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Document
                            file={process.env.PUBLIC_URL + '/files/Contest_rule.pdf'}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page
                                pageNumber={pageNumber}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                                customTextRenderer={false}
                                scale={1.4}
                            />
                        </Document>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, width: '100%' }}>
                            <Button
                                onClick={goToPrevPage}
                                disabled={pageNumber <= 1}
                                variant="contained"
                                color="primary"
                            >
                                Prev
                            </Button>
                            <Button
                                onClick={goToNextPage}
                                disabled={pageNumber >= numPages}
                                variant="contained"
                                color="primary"
                            >
                                Next
                            </Button>
                        </Box>
                        <Typography sx={{ mt: 2 }}>
                            Page {pageNumber} of {numPages}
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </span>
    );
}
