import * as React from 'react';
import CustomButton from './components/CustomButton';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { Grid, TextField, Typography, Button, Box } from '@mui/material';
import { getEmail } from './utils';

import InstructionPanel from './components/InstructionPanel';
import SpeedTest from './components/SpeedTest';
import Fade from '@mui/material/Fade';
import LoadingScreen from './components/LoadingScreen';
import BandwidthTest from './components/BandwidthTest';
import deployment_config from './deployment_config.json';

export default function TokenForm({ email, setInstanceId, token, setToken, isMicEnabled, setIsMicEnabled, completedCaptcha, setCompletedCaptcha, setIsSubmitted, region, setRegion, setCurrentForm, setEmail}) {
    const [latency, setLatency] = React.useState(0);
    const [isLoading, setLoading] = React.useState(false);
    const [bandwidth, setBandwidth] = React.useState(0);
    const apiBaseUrl = deployment_config.apiBaseUrl;

    const clear = () => {
        setToken('');
        setIsMicEnabled(false);
        setCompletedCaptcha(false);
        setIsSubmitted(false);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submit action
            if (token && isMicEnabled) {
                finalSubmit();
            }
        }
    }

    const enableMic = () => {
        // Enable microphone by asking for permissions to use microphone
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                setIsMicEnabled(true);
            })
            .catch(err => {
                alert("We need to use your microphone to play our game. Please enable it and retry.");
            });
    }

    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const urlToken = queryParams.get('token');
        if (urlToken) {
            setToken(urlToken);
        }
        const storedEmail = getEmail();  // Retrieve the email using the new utility function
        if (!email) {
            setEmail(storedEmail); // Set the email state if needed
        }
        checkRegions().then(([region, latency]) => {
            setRegion(region);
            setLatency(latency);
        });
    }, []);   

    async function checkRegions() {
        const regions = {
            'us-east-1': 'https://dynamodb.us-east-1.amazonaws.com/ping',
            'us-east-2': 'https://dynamodb.us-east-2.amazonaws.com/ping',
            'us-west-1': 'https://dynamodb.us-west-1.amazonaws.com/ping',
            'us-west-2': 'https://dynamodb.us-west-2.amazonaws.com/ping',
            'ca-central-1': 'https://dynamodb.ca-central-1.amazonaws.com/ping',
            'eu-west-2': 'https://dynamodb.eu-west-2.amazonaws.com/ping',
            'ap-southeast-2': 'https://dynamodb.ap-southeast-2.amazonaws.com/ping',
        };

        let fastestRegion = '';
        let fastestTime = Infinity;

        const regionPromises = Object.entries(regions).map(async ([region, url]) => {
            let startTime = Date.now();
            console.log(`Pinging ${region}`);

            try {
                await fetch(url, { mode: 'no-cors' }); // mode: 'no-cors' is needed to avoid CORS errors
                let endTime = Date.now();
                let timeTaken = endTime - startTime;

                console.log(`Pinging ${region} and it has a latency of ${timeTaken} ms`);

                if (timeTaken < fastestTime) {
                    fastestTime = timeTaken;
                    fastestRegion = region;
                }
            } catch (error) {
                console.error(`Error pinging ${region}: ${error}`);
            }
        });

        await Promise.all(regionPromises);
        console.log(fastestRegion);

        return [fastestRegion, fastestTime];
    }

    const createInstance = async () => {
        await fetch(apiBaseUrl + '/create-ec2-instance', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'email': email, 'region': region, 'token': token, 'latency': latency, 'bandwidth': bandwidth})
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 304) {
                    alert("PLAICraft thinks you're still playing on the account with this email, probably because you just disconnected or you recently quit and are rapidly rejoining.  Our data gets messed up if you use the same email in two simultaneous sessions and it takes us some time to clean up after disconnects and quits.  Please try a different email or wait a few minutes and try again.");
                    throw new Error("Duplicate sessions with the same email");
                } else {
                    response.json().then(data => {
                        let msg = JSON.stringify(data);
                        if (msg.includes("No free licenses available")) {
                            alert("All available Minecraft accounts are currently in use. Please try again later.");
                        } else if (msg.includes("Email and/or token are invalid.")) {
                            alert("Email and/or token are invalid.");
                        } else if (msg.includes("Token is expired or invalid.")) {
                            alert("Token is expired or invalid.");
                        } else {
                            alert("Something went wrong... please report this and describe what you were doing when this error occurred.");
                        }
                    });
                    throw new Error('Something went wrong.');
                }
            })
            .then(async (data) => {
                setInstanceId(data);
            });
    };

    const finalSubmit = async () => {
        const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
        const isChromium = window.chrome;

        if (!isChromium || isMobileDevice) {
            setCurrentForm('browser-warning');
            return;
        }
        console.log("final submit");
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(async (stream) => {
                setLoading(true);
                try {
                    await createInstance();
                    setIsSubmitted(true);
                    window.gtag_report_conversion(1);
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                }
            })
            .catch(err => {
                alert("We need to use your microphone to play our game. Please enable it and retry.");
                setIsMicEnabled(false);
            });
    }

    return (
        <>
            {isLoading ?
                <Fade in={isLoading} timeout={300}>
                    <div>
                        <LoadingScreen />
                    </div>
                </Fade>
                :
                <form noValidate autoComplete="off" onKeyPress={handleKeyPress}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <InstructionPanel
                                instructions={
                                    <ol>
                                        <li>We are almost done! Check your email now for the token and enter it below.</li>
                                        <li>Enable your microphone. We'll need to hear your beautiful voice to help our research!</li>
                                        <li>Spinning up our PLAIground will take about 5 minutes. Take this time to watch our video and read our FAQ.</li>
                                        <li>Once in the PLAIground, you need to CLICK ON THE SCREEN once to get into full srceen and make the mouse working properly</li>
                                        <li>Remember, DO NOT refresh your page or type CTRL-W while playing. If you require a password, just click in there's no passwd.</li>
                                        <li>Have fun!</li>
                                    </ol>
                                }
                                iframe={
                                    <iframe
                                        width="100%"
                                        height="266"
                                        src="https://www.youtube-nocookie.com/embed/Mu_Rl3MBXCE?autoplay=1"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                                        allowfullscreen
                                    >
                                    </iframe>
                                }
                            />
                        </Grid>
                        <Grid item sx={{ marginTop: -2 }}>
                            <Typography 
                                variant="head1" 
                                color="text.primary" 
                                align="left" 
                                sx={{
                                    position: "relative",
                                    top: 13,
                                    fontWeight: 'bold'
                                }}
                            >
                                Please check your email for the token we just sent you.
                            </Typography>
                        </Grid>
                        <Grid item sx={{ marginTop: 0 }}>
                            <TextField label="Token" variant="outlined" fullWidth value={token} onChange={(e) => setToken(e.target.value)} />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                disabled={!token}
                                color={isMicEnabled ? "success" : "error"}
                                onClick={() => enableMic()}
                                fullWidth
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                {isMicEnabled ? (
                                    <MicIcon fontSize="medium" sx={{ color: "white" }} />
                                ) : (
                                    <>
                                        <MicOffIcon fontSize="medium" />
                                        <Typography
                                            variant="body2"
                                            color="inherit"
                                            sx={{ ml: 1 }}
                                        >
                                            Click HERE to open mic
                                        </Typography>
                                        <MicOffIcon fontSize="medium" />
                                    </>
                                )}
                            </Button>
                            <Box sx={{ position: "relative", height: "1.5em" }}>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    align="center"
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        opacity: isMicEnabled ? 0 : 1,
                                        transition: "opacity 0.3s ease-in-out",
                                    }}
                                >
                                    Please consider enabling your MIC, speech is crucial for the research
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    align="center"
                                    sx={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        opacity: isMicEnabled ? 1 : 0,
                                        transition: "opacity 0.3s ease-in-out",
                                    }}
                                >
                                    Thank you! Please keep your environment QUIET as well!
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid container item direction="row" alignItems="center" spacing={1} sx={{ display: "flex", justifyContent: "center" }}>
                            <Grid item sx={{ width: '50%' }}>
                                <SpeedTest latency={latency} />
                            </Grid>
                            <Grid item sx={{ width: '50%' }}>
                                <BandwidthTest bandwidth={bandwidth} setBandwidth={setBandwidth} />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                align="center"
                                sx={{ visibility: (latency > 200 || bandwidth < 60) ? "visible" : "hidden" }}
                            >
                                <b>Note:</b> Your experience might be affected by poor connectivity.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                align="center"
                            >
                                <b style={{ color: "red" }}>Note:</b> Once in the game, you need to CLICK on the screen once to enter fullscreen and make the mouse working!!!
                            </Typography>
                        </Grid>
                        <Grid container item direction="row" alignItems="center" spacing={0} sx={{ display: "flex", justifyContent: "center" }}>
                            <Grid item>
                                <CustomButton
                                    isDisabled={!token || !isMicEnabled}
                                    content="Submit"
                                    color="success"
                                    onClick={() => {
                                        finalSubmit();
                                    }}
                                />
                            </Grid>
                            <Grid item ml={2}>
                                <CustomButton
                                    isDisabled={false}
                                    content="Clear"
                                    color="primary"
                                    onClick={clear}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            }
        </>
    );
}
