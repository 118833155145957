import * as React from 'react';
import { Typography, Box, Container, IconButton, Modal } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import FAQ from './components/faq';
import deployment_config from './deployment_config.json';

export default function ProgressBar({ instanceId, region, isRedirecting, setRedirecting }) {
    const [progress, setProgress] = React.useState(0); // initial progress is 0
    const [message, setMessage] = React.useState('Initializing plaiground... (approx. 5 minutes)');
    const [msgTimer, setMsgTimer] = React.useState(null);
    const [open, setOpen] = React.useState(true);
    const [counter, setCounter] = React.useState(0);
    const [readyForRedirect, setReadyForRedirect] = React.useState(false);
    const [instanceIP, setInstanceIP] = React.useState(null);
    const apiBaseUrl = deployment_config.apiBaseUrl;

    const minecraftMsgs = [
        "Remember to use a headphone!",
        "Type '/minigames' to access fun mini games!",
        "Invite your friends to plai tgt!",
        "Once in game, click the screen to make the mouse working!",
        "Join our discord and make friends!"
    ]

    const get_ip_address = async (instance) => {
        const response = await fetch(apiBaseUrl + '/get-ec2-instance-status', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ instance_id: instance, region_name: region })
        })
        const data = await response.json();
        setMessage(minecraftMsgs[Math.floor(Math.random() * minecraftMsgs.length)]);
        setInstanceIP(data.state);
        return data.state;
    }

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 99) {
                    clearInterval(timer);
                    return 99;
                }
                return Math.min(oldProgress + 3, 99);
            });

            get_ip_address(instanceId).then((state) => {
                console.log(state)
                if (["initializing"].includes(state) || !state || state == "None" || state == "null") {
                    setCounter(oldCounter => {
                        if (oldCounter > 30 || ["impaired", "insufficient-data", "not-applicable"].includes(state)) {
                            alert("Something went wrong. Please try again.");
                            return oldCounter; // Don't update the counter if we're showing an alert
                        }
                        return oldCounter + 1; // always using the most current value of counter
                    });
                } else {
                    const audio = new Audio(process.env.PUBLIC_URL + '/ready.mp3');
                    audio.play().then(() => {
                        setReadyForRedirect(true);
                    }).catch((error) => {
                        console.error("Error playing sound:", error);
                        setReadyForRedirect(true);
                    });
                }
            });
        }, 10000);

        return () => clearInterval(timer);
    }, [instanceIP, apiBaseUrl]);

    React.useEffect(() => {
        if (readyForRedirect) {
            setRedirecting(true);
            setProgress(100);
            setMessage("Happy Mining!");
            clearInterval(msgTimer);
            setTimeout(() => {
                window.location.href = `/plaiground/index.html?ip=${instanceIP}`;
            }, 100);
        }
    }, [readyForRedirect, setRedirecting, instanceIP]);

    const handleOpen = (event) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container>
            <Typography variant="h6" gutterBottom color="white" textAlign="center" fontFamily="Minecraftia" sx={{ mb: 3 }} >
                {message} <br /> <br /> {progress}% <br /> <br /> DO NOT REFRESH THE PAGE
            </Typography>
            <Box sx={{
                width: '100%',
                height: '20px',
                bgcolor: '#5a5a5a',
                border: '2px solid #000000',
                boxSizing: 'border-box',
                position: 'relative'
            }}>
                <Box sx={{
                    height: '100%',
                    width: `${progress}%`, // width is set dynamically here
                    bgcolor: '#71e849',
                    transition: 'width .4s ease-in-out',
                    position: 'relative',
                    overflow: 'hidden',
                }} />
                <IconButton
                    color="primary"
                    onClick={handleOpen}
                    sx={{ position: 'absolute', right: -50, bottom: 150 }}
                >
                    <InfoIcon />
                </IconButton>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '70%',
                            height: '80%',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            overflow: 'auto',
                            padding: '50px',
                        }}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Instructions
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            So now you are ready to play… Follow these last steps.<br/>
                            <ol>
                                <li>Once the PLAIGround is ready, you will be automatically redirected to the website</li>
                                <li>If you don’t see Minecraft at first, give it a few seconds before it starts up.</li>
                                <li>Once it is ready, click the middle of the screen a few times. You will be put into fullscreen mode, your microphone will be turned on, and your mouse will be focused into our PLAIGround</li>
                                <li>Remember, do NOT type CTRL-W or refresh your page while you play.</li>
                                <li>Once you are finished, please close your browser tab. You will need to long press the esc key to exit the fullscreen. </li>
                                <li>If you have any questions or any issues, please read the <FAQ /> or send us an email <a href="https://forms.gle/DfL7oHo1f1xsR4Xu7" target="_blank" rel="noreferrer" onClick={() => {window.open('mailto:support@plaicraft.ai')}}>here.</a><br/> </li>
                            </ol>
                            Whenever you leave fullscreen, be sure to click the middle of the screen a few times to re-enter fullscreen mode.<br/><br/>

                            Your URL is <b>UNIQUE</b>. To reconnect to your session, you will need to note down your URL. Please see the <FAQ /> for more information about reconnections.<br/><br/>

                            Thanks for your contribution!<br/>
                            The PLAICraft Team<br/><br/>

                            <iframe 
                                width="100%" 
                                height="550" 
                                src="https://www.youtube-nocookie.com/embed/4gRGcRPE1_0?autoplay=1" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" 
                                allowfullscreen
                            />
                        </Typography>
                    </Box>
                </Modal>
            </Box>
        </Container>
    );
}