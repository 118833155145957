import * as React from 'react';
import { Container, Box, Fade } from '@mui/material';
import Footer from './components/footer';
import BackgroundVideo from './BackgroundVideo';
import EmailForm from './EmailForm';
import TokenForm from './TokenForm';
import UserForm from './UserForm';
import PromptForm from './PromptForm';
import BrowserWarningForm from './BrowserWarningForm';
import ProgressBar from './ProgressBar';
import { useSearchParams } from 'react-router-dom';
import deployment_config from './deployment_config.json';
import { Helmet } from 'react-helmet';
import { storeEmail, getEmail } from './utils';

export default function App() {
  const [searchParams] = useSearchParams();
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [age, setAge] = React.useState(17);
  const [experience, setExperience] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [consent, setConsent] = React.useState(false);
  const [reconsent, setReconsent] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [individualId, setIndividualId] = React.useState('');
  const [organizationName, setOrganizationName] = React.useState('');
  const [promptId, setPromptId] = React.useState(0);
  const [isMicEnabled, setIsMicEnabled] = React.useState(false);
  const [completedCaptcha, setCompletedCaptcha] = React.useState(false);
  const [currentForm, setCurrentForm] = React.useState('email');
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [instanceId, setInstanceId] = React.useState('');
  const [isRedirecting, setRedirecting] = React.useState(false);
  const [region, setRegion] = React.useState('');
  const [subscribe, setSubscribe] = React.useState(true);

  React.useEffect(() => {
    const beforeUnloadListener = (ev) => {
      ev.preventDefault();
      return ev.returnValue = 'Are you sure you want to close?';
    };

    const unloadListener = () => {
      fetch(deployment_config.apiBaseUrl + '/afk-kick', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "INSTANCE_IP": instanceId
        }),
      }).then(response => {
        if (response.ok) {
          console.log("Successfully called afk-kick");
        } else {
          console.warn("Error in calling afk-kick");
        }
      }).catch(error => {
        console.error('Error with calling API:', error);
      });
    };

    if (!isRedirecting) {
      window.addEventListener("beforeunload", beforeUnloadListener);
      window.addEventListener("unload", unloadListener);
    }

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
      window.removeEventListener("unload", unloadListener);
    };
  }, [isRedirecting]);

  React.useEffect(() => {
      const storedEmail = getEmail();
      if (searchParams.get("token") && !searchParams.get("promptId")) {
          setToken(searchParams.get("token"));
          setCurrentForm('token');
          setEmail(storedEmail);
      }

      if (searchParams.get("promptId") && searchParams.get("email") && searchParams.get("token")) {
          if (searchParams.get("promptId") !== null && searchParams.get("promptId") !== "") {
              setPromptId(Number(searchParams.get("promptId")));
              setEmail(String(searchParams.get("email")));
              setToken(searchParams.get("token"));
              setCurrentForm('prompt');
          }
      }

      if (searchParams.get("email")) {
          storeEmail(String(searchParams.get("email"))); // Ensure email is stored
          setEmail(String(searchParams.get("email")));
      }

      if (searchParams.get("individualId")) {
          setIndividualId(String(searchParams.get("individualId")));
      }

      if (searchParams.get("organizationName")) {
          setOrganizationName(String(searchParams.get("organizationName")));
      }

  }, [searchParams]);

  const videoSource = `/output.mp4`;
  const titleSource = `/PLAICraftTitle.png`

  return (
    <Container
      maxWidth="sm"
      sx={{
        padding: { xs: '20px', sm: '50px' }, // Adjust padding for mobile
        overflow: 'auto',
        width: '100%', // Ensure the container is responsive
        height: '75%'
      }}
    >
      <Helmet>
        <title>Free Minecraft for AI Research!</title>
        <meta name="description" content="Play Minecraft for free. Contribute to AI research" />
      </Helmet>
      <img src={process.env.PUBLIC_URL + titleSource} alt="" width="100%" />
      <BackgroundVideo videoSource={process.env.PUBLIC_URL + videoSource} />
      {isSubmitted ?
        <Fade in={isSubmitted}>
          <Box sx={{
            my: 2,
            border: '1px solid grey',
            borderRadius: '10px',
            p: 3,
            bgcolor: 'rgba(0, 0, 0, 0.8)',
          }}
          >
            <ProgressBar instanceId={instanceId} region={region} isRedirecting={isRedirecting} setRedirecting={setRedirecting} />
          </Box>
        </Fade>
        :
        <Box sx={{
          my: 2,
          border: '1px solid grey',
          borderRadius: '10px',
          p: 3,
          backgroundColor: 'grey.200',
          width: '100%',
        }}
        >
          <Fade in={currentForm === 'email'} timeout={500}>
            <Box>{currentForm === 'email' && <EmailForm email={email} setEmail={setEmail} setAge={setAge} setCurrentForm={setCurrentForm} reconsent={reconsent} setReconsent={setReconsent}/>} </Box>
          </Fade>
          <Fade in={currentForm === 'prompt'} timeout={500}>
            <Box>{currentForm === 'prompt' && <PromptForm promptId={promptId} setInstanceId={setInstanceId} email={email} token={token} setCurrentForm={setCurrentForm} region={region} setRegion={setRegion} isMicEnabled={isMicEnabled} setIsMicEnabled={setIsMicEnabled} completedCaptcha={completedCaptcha} setCompletedCaptcha={setCompletedCaptcha} setIsSubmitted={setIsSubmitted}/>} </Box>
          </Fade>
          <Fade in={currentForm === 'user'} timeout={500}>
            <Box>
              {currentForm === 'user' &&
                <UserForm
                  email={email}
                  setEmail={setEmail}
                  name={name}
                  setName={setName}
                  age={age}
                  setAge={setAge}
                  experience={experience}
                  setExperience={setExperience}
                  gender={gender}
                  setGender={setGender}
                  consent={consent}
                  setConsent={setConsent}
                  setCurrentForm={setCurrentForm}
                  individualId={individualId}
                  setIndividualId={setIndividualId}
                  organizationName={organizationName}
                  setOrganizationName={setOrganizationName}
                  subscribe={subscribe}
                  setSubscribe={setSubscribe}
                />
              }
            </Box>
          </Fade>
          <Fade in={currentForm === 'token'} timeout={500}>
            <Box>
              {currentForm === 'token' &&
                <TokenForm
                  email={email}
                  setInstanceId={setInstanceId}
                  token={token}
                  setToken={setToken}
                  isMicEnabled={isMicEnabled}
                  setIsMicEnabled={setIsMicEnabled}
                  completedCaptcha={completedCaptcha}
                  setCompletedCaptcha={setCompletedCaptcha}
                  setIsSubmitted={setIsSubmitted}
                  region={region}
                  setRegion={setRegion}
                  setCurrentForm={setCurrentForm}
                  setEmail={setEmail}
                />
              }
            </Box>
          </Fade>
          <Fade in={currentForm === 'browser-warning'} timeout={500}>
            <Box>{currentForm === 'browser-warning' && <BrowserWarningForm />}</Box>
          </Fade>
          <Footer />
        </Box>
      }
    </Container>
  );
}
