// src/utils.js
export function storeEmail(email) {
    if (email) {
        localStorage.setItem('email', email);
        sessionStorage.setItem('email', email);
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('email', email);
        window.history.replaceState(null, null, "?" + urlParams.toString());
    } else {
        console.error("Attempted to store empty or invalid email.");
    }
}

export function getEmail() {
    let email = localStorage.getItem('email') || sessionStorage.getItem('email');
    if (!email) {
        const urlParams = new URLSearchParams(window.location.search);
        email = urlParams.get('email');
    }
    if (!email) {
        console.warn("Email not found in any storage.");
    }
    return email;
}
